import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Flex, Text, Heading, TextLink } from '@feast-it/pesto';
import ReactGA from 'react-ga';
import Router from 'next/router';

import Link from '../../components/Link';
import { trackEvent } from '../../modules/analytics';
import { stringifyURL } from '../../modules/formatting';
import { GET_QUOTES_CLICKED } from '../../constants/analytics';
import Option from '../../components/Option';
import PrismicContext from '../../context/prismic';
import { TIER_1_ICONS } from '../../constants/verticals';

const initialValues = {
  tier1: '',
};

const createSchema = Yup.object().shape({
  tier1: Yup.string(),
});

const HomepageHeroVariantTwo = ({ path, buttonText, helpLink }) => {
  const { tier1Verticals } = useContext(PrismicContext);

  const handleSubmit = ({ tier1 }) => {
    trackEvent(GET_QUOTES_CLICKED, { type: 'Homepage hero' });

    ReactGA.event({
      category: 'Enquiry',
      action: GET_QUOTES_CLICKED,
      value: 1,
    });

    const query = { tier1 };

    Router.push(stringifyURL(path, query));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createSchema}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <Form>
            <Flex
              flexDirection="column"
              alignItems={{ _: 'center', m: 'flex-start' }}
            >
              <Heading
                as="p"
                fontSize="headingSmall"
                color={{ _: 'white', m: 'primary' }}
                mt={9}
              >
                I’m looking for&hellip;
              </Heading>

              <Flex
                flexWrap="wrap"
                justifyContent={{ _: 'center', m: 'flex-start' }}
                mt={3}
              >
                {tier1Verticals.map(tier1 => (
                  <Field
                    key={tier1.sysName}
                    component={Option}
                    icon={TIER_1_ICONS[tier1.sysName]}
                    label={tier1.name}
                    value={tier1.sysName}
                    variant={{ _: 'primaryInverted', m: 'primary' }}
                    name="tier1"
                    mt={3}
                    mr={2}
                  />
                ))}
              </Flex>

              <Button
                type="submit"
                variant={{ _: 'light', m: 'primary' }}
                size="large"
                fontSize="large"
                mt={5}
              >
                {buttonText}
              </Button>

              <Text color={{ _: 'white', m: 'greyDark' }} mt={3}>
                Don’t know where to start?{' '}
                <TextLink
                  color={{ _: 'white', m: 'primary' }}
                  href={helpLink}
                  linkComponent={Link}
                >
                  See our services
                </TextLink>
              </Text>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

HomepageHeroVariantTwo.propTypes = {
  path: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  helpLink: PropTypes.string.isRequired,
};

export default HomepageHeroVariantTwo;
