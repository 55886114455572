import React from 'react';
import PropTypes from 'prop-types';
import { base, Box, Image } from '@feast-it/pesto';
import { useBreakpoint } from '../../hooks/media';

const BlobHeroImage = ({ src, alt }) => {
  const isDesktop = useBreakpoint(base.breakpoints.l);

  return (
    <Box width="100%" flexShrink={0}>
      <Image
        src={src}
        alt={alt}
        width={isDesktop ? 1480 : 750}
        height={isDesktop ? 1280 : 640}
        inView
        imgix
        options={{
          fit: 'crop',
          dpr: '1',
        }}
      />
    </Box>
  );
};

BlobHeroImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default BlobHeroImage;
