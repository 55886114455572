import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

export default function Highlight({ children, ...props }) {
  return <Styled.Highlight {...props}>{children}</Styled.Highlight>;
}

Highlight.propTypes = {
  children: PropTypes.node,
};
