import styled from '@emotion/styled/macro';
import { minWidth, maxWidth, Box, rem } from '@feast-it/pesto';
import { DesktopSVGShape, MobileSVGShape } from './BlobSvgs';

export const BlobHero = styled(Box)`
  background: ${({ theme }) => theme.colors.secondary};
  overflow: hidden;

  ${({ theme }) => minWidth(theme.breakpoints.m)} {
    background: ${({ theme }) => theme.colors.white};
  }
`;

export const Blob = styled.div`
  flex: 1;
  position: relative;

  ${({ theme }) => minWidth(theme.breakpoints.m)} {
    margin-right: ${rem(-40)};
  }
`;

export const BlobImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${({ theme }) => minWidth(theme.breakpoints.m)} {
    left: ${rem(40)};
  }
`;

export const ShapeDesktop = styled(DesktopSVGShape)`
  ${({ theme }) => maxWidth(theme.breakpoints.m)} {
    display: none;
  }
`;

export const ShapeMobile = styled(MobileSVGShape)`
  ${({ theme }) => minWidth(theme.breakpoints.m)} {
    display: none;
  }
`;
