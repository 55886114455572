import React, { Fragment } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Flex,
  Heading,
  TextLink,
  columnWidth,
  Carousel,
  rem,
} from '@feast-it/pesto';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';

import Link from '../Link';
import Mask from '../Mask';
import Highlight from '../../components/Highlight';
import Image from './Image';
import { useTheme } from 'emotion-theming';
import { useBreakpoint } from '../../hooks/media';

import * as Styled from './styles';

const HEADER_HEIGHT = rem(60);

import Reviews from './reviews';

const ParagraphToSpanOverride = props => <span {...props} />;

const BlobHero = ({
  children,
  title,
  subtitle,
  images,
  showReviews,
  breadcrumbs,
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const theme = useTheme();
  const isMobile = !useBreakpoint(theme.breakpoints.m);

  const hasImages = !!images?.length;
  const hasOneImage = hasImages && images.length === 1;

  return (
    <Styled.BlobHero ref={ref} mt={`-${HEADER_HEIGHT}`}>
      <Grid
        as="section"
        gridTemplateColumns={{ _: '100%', m: '1fr 1fr' }}
        pt={HEADER_HEIGHT}
      >
        <GridItem>
          <Flex
            flexDirection="column"
            alignItems={{ _: 'center', m: 'flex-start' }}
            justifyContent="center"
            height="100%"
            minHeight={{ m: rem(520) }}
            maxWidth={{ m: columnWidth(7) }}
            px={{ _: 'gutter.s', m: 'gutter.m' }}
            pt={{ _: 5, s: 9 }}
            pb={9}
          >
            {breadcrumbs && (
              <nav>
                <Flex as="ol" mb={7}>
                  {breadcrumbs.map(({ text, href, as }, i) => {
                    const isLastItem = i === breadcrumbs.length - 1;
                    const isSecondToLastItem = i === breadcrumbs.length - 2;

                    return (
                      <Fragment key={`breadcrumb-${i}`}>
                        <li>
                          <TextLink
                            display={{
                              _: isSecondToLastItem ? 'block' : 'none',
                              m: 'block',
                            }}
                            variant={{ _: 'small', m: 'body' }}
                            color={{ _: 'white', m: 'blueDark' }}
                            href={href}
                            linkComponent={Link}
                            as={as}
                            mr={2}
                          >
                            {isLastItem ? <strong>{text}</strong> : <>{text}</>}
                          </TextLink>
                        </li>

                        {!isLastItem && (
                          <li aria-hidden={true}>
                            <Box
                              display={{ _: 'none', m: 'block' }}
                              color={{ _: 'white', m: 'blueDark' }}
                              mr={2}
                            >
                              /
                            </Box>
                          </li>
                        )}
                      </Fragment>
                    );
                  })}
                </Flex>
              </nav>
            )}

            <Heading
              color={{ _: 'white', m: 'blueDark' }}
              variant={{ _: 'displayMobile', l: 'display' }}
              textAlign={{ _: 'center', m: 'left' }}
              mb={4}
            >
              <Markdown
                options={{
                  forceBlock: true,
                  overrides: {
                    p: {
                      component: ParagraphToSpanOverride,
                    },
                    strong: {
                      component: Highlight,
                      props: {
                        color: { _: 'tertiary', m: 'primary' },
                      },
                    },
                  },
                }}
              >
                {title}
              </Markdown>
            </Heading>

            <Heading
              as="h2"
              color={{ _: 'white', m: 'blueDark' }}
              variant={{ _: 'headingSmall', l: 'headingMedium' }}
              textAlign={{ _: 'center', m: 'left' }}
            >
              {subtitle}
            </Heading>

            {children}
          </Flex>
        </GridItem>

        <GridItem
          gridColumn={{ m: '2' }}
          gridRow={{ m: '1 / span 2' }}
          pb={{ m: 4 }}
        >
          <Flex
            height="100%"
            alignItems="center"
            bg={{ _: 'backDrop', m: 'white' }}
          >
            <Styled.Blob>
              <Styled.BlobImage>
                <Mask
                  src={{
                    _: '/images/generic/mask-7.svg',
                    m: '/images/generic/mask-6.svg',
                  }}
                >
                  {hasOneImage ? (
                    <Image src={images[0].src} alt={images[0].alt} />
                  ) : hasImages ? (
                    <Carousel
                      dots={false}
                      options={{
                        autoplay: 5000,
                        hoverpause: true,
                        animationDuration: 400,
                      }}
                      arrowOptions={{
                        position: { _: 'center', m: 'bottom-right' },
                        right: { m: 120 },
                        bottom: { m: 42 },
                        gap: 40,
                      }}
                    >
                      {images.map(image => (
                        <Image
                          key={`blob-image-${image.src}`}
                          src={image.src}
                          alt={image.alt}
                        />
                      ))}
                    </Carousel>
                  ) : null}
                </Mask>
              </Styled.BlobImage>
              {isMobile ? (
                <Styled.ShapeMobile primary="secondary" tertiary="tertiary" />
              ) : (
                <Styled.ShapeDesktop fill="secondary" />
              )}
            </Styled.Blob>
          </Flex>
        </GridItem>

        {showReviews && (
          <GridItem>
            <Flex
              height="100%"
              alignItems="flex-end"
              justifyContent={{ _: 'center', m: 'flex-start' }}
              px={{ _: 'gutter._', s: 'gutter.s', m: 'gutter.m' }}
              bg={{ _: 'backDrop', m: 'white' }}
              pt={{ _: 6, m: 0 }}
              pb={6}
            >
              <Reviews inView={inView} />
            </Flex>
          </GridItem>
        )}
      </Grid>
    </Styled.BlobHero>
  );
};

BlobHero.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    })
  ),
  showReviews: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.array,
};

export default BlobHero;
