import React, { useEffect } from 'react';
import get from 'just-safe-get';
import { Button } from '@feast-it/pesto';
import { useRouter } from 'next/router';

import BlobHero from '../../components/BlobHero';
import Form from '../../forms/homepage-hero';

import paths from '../../constants/paths';
import { trackEvent, formatPathId } from '../../modules/analytics';

const HeroContainer = rawData => {
  const { query, asPath } = useRouter();

  const getHeroImages = images => {
    if (!images) {
      return [];
    }

    const key = 'blob_hero_image';

    return images.map(image => ({
      src: image[key].url,
      alt: image[key].alt,
    }));
  };

  const data = {
    title: get(rawData, 'primary.blob_hero_title'),
    subtitle: get(rawData, 'primary.blob_hero_subtitle'),
    images: getHeroImages(get(rawData, 'items')),
    buttonText: get(rawData, 'primary.blob_hero_text') || 'Get quotes',
    buttonLink:
      get(rawData, 'primary.blob_hero_link.url') || paths.ENQUIRY_FORM,
    showReviews: get(rawData, 'primary.blob_hero_show_reviews'),
    variant: get(rawData, 'primary.blob_hero_variant'),
    helpLink: get(rawData, 'primary.blob_hero_help_link') ?? paths.SITEMAP,
  };

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'optimize.activate' });
    }
  }, [rawData]);

  return (
    <BlobHero {...data}>
      {data.variant === 'homepage' && (
        <Form
          path={data.buttonLink}
          buttonText={data.buttonText}
          helpLink={data.helpLink}
        />
      )}

      {data.variant === 'none' && (
        <Button
          href={data.buttonLink}
          variant={{ _: 'light', m: 'primary' }}
          size="large"
          fontSize="large"
          mt={6}
          onClick={() => {
            query?.id &&
              trackEvent(`${formatPathId(query.id)} CTA Clicked`, {
                url: asPath,
              });
          }}
        >
          {data.buttonText}
        </Button>
      )}
    </BlobHero>
  );
};

export default HeroContainer;
