import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'emotion-theming';

export const DesktopSVGShape = ({ fill }) => {
  const theme = useTheme();

  return (
    <svg viewBox="0 0 780 640" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M125.666 579.713C-77.6384 543.658 -45.5817 -31.0547 305.177 1.31721C679.77 35.8888 782.37 182.669 773.948 317.315C768.467 404.948 812.815 600.745 721.627 621.276C511.845 668.508 328.971 615.767 125.666 579.713Z"
        fill={theme.colors[fill] ?? '#00387F'}
      />
    </svg>
  );
};

export const MobileSVGShape = ({ primary, tertiary }) => {
  const theme = useTheme();

  return (
    <svg viewBox="0 0 375 320" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={theme.colors[primary] ?? '#1C3485'} d="M0 0h375v154H0z" />
      <path
        d="M375 16.705V154.5H0L111 54c82.637-68.698 188.072-59.582 264-37.295z"
        fill={theme.colors[tertiary] ?? '#FF7364'}
      />
    </svg>
  );
};

DesktopSVGShape.propTypes = {
  fill: PropTypes.string,
};

MobileSVGShape.propTypes = {
  primary: PropTypes.string,
  tertiary: PropTypes.string,
};
