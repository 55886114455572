import React from 'react';
import PropTypes from 'prop-types';
import { Box, rem } from '@feast-it/pesto';

import useScript from '../../hooks/useScript';

const Reviews = ({ inView }) => {
  useScript(
    'https://widget.reviews.co.uk/text-banner/dist.js',
    inView,
    () =>
      window.reviewsTextBanner &&
      window.reviewsTextBanner('text-banner-widget', {
        store: 'feast-it-com',
        logoClr: '#12cf6c',
        starsClr: '#F7B500',
        textClr: '#696c7b',
        css: '.icon-rev-star-small { color: #14d37d !important }',
      })
  );

  return (
    <Box width={rem(340)} height={rem(70)} ml={rem(-20)}>
      <div id="text-banner-widget" />
    </Box>
  );
};

Reviews.propTypes = {
  inView: PropTypes.bool.isRequired,
};

export default Reviews;
